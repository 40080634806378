body {
  font: 16px "Century Gothic", Futura, sans-serif;
  margin: 0px;
  line-height: 1.8em;
  background-color: #262626;
}

ol, ul {
  padding-left: 30px;
}

a {
  color: #bababa;
}

a:visited {
  color: #825aaa;
}

button {
  padding: 4px;
  cursor: pointer;
}

.page-header {
  font-size: 20px;
  width: 100%;
  position: fixed;
  /*background-color: #696969;*/
  background-image: linear-gradient(to right, #3c9625 , #ad6fe5);
  color: white;
  top: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  /*text-align: center;*/
  display: flex;
  justify-content: space-around;
}

.page-content {
  padding-top: 40px;
  width: 80%;
  margin: auto;
}
.item-date {
  font-family: "Courier New", serif;
  color: #00bb00;
  padding-right: 8px;
}
.rssfeed-site-section {
  border-bottom: 1px solid gray;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 12px;
  padding-top: 12px;
}

.rssfeed-site-section-title {
  font-size: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}

.rssfeed-site-section-title > a {
  color: white;
}
.rssfeed-site-section-title > a:visited {
  color: white;
}

.rssfeed-site-section-title-button {
  align-items: middle;
  border: 1px solid #707070;
  background-color: #696969;
  padding-top: 5px;
  padding-left: 4px;
  padding-right: 4px;
  color: #ddd;
  font: 400 11px system-ui;
  cursor: pointer;
}

.page-footer {
  width: 100%;
  background-color: rgb(167, 222, 167);
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
}
